import { useEffect, useState } from 'react';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import GlobalsPolling from './common/globals-polling.tsx';
import { DEPENDENCIES, POLLING_TIMEOUT_MS } from './constants.tsx';

let pollingSingleton: GlobalsPolling | null = null;

export const resetPollingSingleton = () => {
	pollingSingleton = null;
};

class PollingTimedOutError extends Error {
	skipSentry: boolean;

	constructor() {
		super('Timed out polling for globals');
		this.skipSentry = true;
	}
}

export const useIsGlobalsReady = (onTimeOutCallback?: () => void) => {
	try {
		pollingSingleton = pollingSingleton ?? new GlobalsPolling(100, POLLING_TIMEOUT_MS, logger);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		logger.safeWarnWithoutCustomerData(
			'spa-apps.dashboard.common',
			'error creating globals polling singleton',
			error,
		);
	}

	// IMPORTANT - must evaluate readiness synchronously on the first call to avoid async delay
	const [isGlobalsReady, setIsGlobalsReady] = useState<boolean>(
		() => pollingSingleton?.isReady ?? false,
	);
	const [isTimedOut, setIsTimedOut] = useState<boolean>(
		() => pollingSingleton?.isTimedOut ?? false,
	);

	useEffect(() => {
		const setGlobalsReady = () => {
			setIsGlobalsReady(true);
		};
		const setTimedOut = () => {
			setIsTimedOut(true);
		};

		pollingSingleton?.addEventListener('onReady', setGlobalsReady);
		pollingSingleton?.addEventListener('onTimeout', setTimedOut);

		return () => {
			pollingSingleton?.removeEventListener('onReady', setGlobalsReady);
			pollingSingleton?.removeEventListener('onTimeout', setTimedOut);
		};
	}, []);

	// NOTE - additional state and effect is needed for error to trigger react ErrorBoundary
	useEffect(() => {
		if (isTimedOut) {
			if (fg('endeavour_send_failure_on_globals_timeout')) {
				onTimeOutCallback?.();
			}
			throw new PollingTimedOutError();
		}
	}, [isTimedOut, onTimeOutCallback]);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return pollingSingleton !== null ? isGlobalsReady : DEPENDENCIES.every((dep) => window[dep]);
};
